import { useEffect, useState } from 'react';
import { isNullish } from 'remeda';

export const useGetImageDominantColor = (src?: string | null) => {
  const [dominantColor, setDominantColor] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    const abortController = new AbortController();

    if (!isNullish(src)) {
      const imageUrl = encodeURIComponent(src);

      fetch(`/api/v1/image/meta/${imageUrl}`, {
        signal: abortController.signal,
      })
        .then(async response => {
          if (response.ok) {
            const { color } = await response.json();

            setDominantColor(color);

            return true;
          }
          return false;
        })
        .catch(() => {});
    }

    return () => {
      setDominantColor(undefined);
      abortController.abort();
    };
  }, [setDominantColor, src]);

  return dominantColor;
};
